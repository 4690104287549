import React from "react";
import ProductsDemo from "../../../page-components/ProductsDemo";
import { RESERVE } from "../../../utils/firebase/forms";

export default function DemoPage() {
  return (
    <ProductsDemo
      lineOfBusiness="Hospitality"
      productInterest="Reserve"
      collection={RESERVE}
    />
  );
}
